/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Sheila from './sheila_lg.jpg'

const buttonAndMessage = {
  alignItems: 'center',
  display: 'flex',
  marginTop: 16,
  p: {
    marginLeft: 16,
  },
}

const cssErrorMessage = {
  color: '#940000',
}

const mainText = {
  p: {
    margin: '0 0 16px 0',
    '@media (min-width: 768px)': {
      fontSize: '1.1em',
    },
    '@media (min-width: 1024px)': {
      fontSize: '1.18em',
    },
  },
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class HomeIndex extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      showSuccess: false,
      name: '',
      errorName: '',
      email: '',
      errorEmail: '',
      message: '',
      errorMessage: '',
    }
  }

  resetErrorMessages = () => {
    this.setState({
      errorName: '',
      errorEmail: '',
      errorMessage: '',
    })
  }

  validateForm = () => {
    const emailCheck = new RegExp(/.+@.+\..+/)
    const emptyFieldErrorMessage = 'Please fill in this field'

    const { name, email, message } = this.state

    let formIsValid = true

    if (name === '') {
      this.setState({
        errorName: emptyFieldErrorMessage,
      })
      formIsValid = false
    }
    if (email === '') {
      this.setState({
        errorEmail: emptyFieldErrorMessage,
      })
      formIsValid = false
    } else if (emailCheck.test(email) === false) {
      this.setState({
        errorEmail: 'Please check that email',
      })
      formIsValid = false
    }
    if (message === '') {
      this.setState({
        errorMessage: emptyFieldErrorMessage,
      })
      formIsValid = false
    } else if (message.length < 5) {
      this.setState({
        errorMessage: 'Can you please give us some more information?',
      })
      formIsValid = false
    }
    return formIsValid
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit(e) {
    this.resetErrorMessages()
    if (this.validateForm() === true) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'lumin-properties-contact',
          ...this.state,
        }),
      })
        .then(() => {
          this.setState({
            name: '',
            email: '',
            message: '',
            showSuccess: true,
          })
          window.clearSuccessMessage = setTimeout(() => {
            this.setState({
              showSuccess: false,
            })
          }, 3000)
        })
        .catch(error => alert(error))
    }
    e.preventDefault()
  }

  render() {
    const siteTitle = 'Lumin Property'
    const siteDescription = 'Welcome'

    const {
      name,
      errorName,
      email,
      errorEmail,
      message,
      errorMessage,
      showSuccess,
    } = this.state

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
        </Helmet>

        <div id="main">
          <section id="one" css={mainText}>
            <div
              css={{
                position: 'relative',
                '@media (min-width: 768px)': {
                  display: 'flex',
                },
                '@media (min-width: 1024px)': {
                  alignItems: 'center',
                },
              }}
            >
              <div
                css={{
                  alignItems: 'center',
                  background: '#f2b820',
                  borderRadius: 15,
                  boxShadow: `  
                    0 1.4px 1.1px rgba(0, 0, 0, 0.034),
                    0 3.3px 2.65px rgba(0, 0, 0, 0.048),
                    0 6.25px 5px rgba(0, 0, 0, 0.06),
                    0 11px 9px rgba(0, 0, 0, 0.072)
                  `,
                  display: 'flex',
                  flexShrink: 0,
                  float: 'left',
                  height: 100,
                  justifyContent: 'center',
                  left: -20,
                  marginBottom: 16,
                  position: 'relative',
                  top: -20,
                  transition: 'all .1s ease-in-out',
                  transform: 'rotate(-3deg)',
                  width: 100,
                  '@media (min-width: 768px)': {
                    float: 'none',
                    height: 140,
                    left: -15,
                    marginBottom: 0,
                    marginRight: 22,
                    position: 'relative',
                    top: -10,
                    width: 140,
                  },
                  '@media (min-width: 1024px)': {
                    height: 200,
                    left: 0,
                    marginRight: 45,
                    top: 0,
                    width: 200,
                  },
                }}
              >
                <svg
                  css={{
                    height: 54 * 0.45,
                    width: 115 * 0.45,
                    '@media (min-width: 768px)': {
                      height: 54 * 0.66,
                      width: 115 * 0.66,
                    },
                    '@media (min-width: 1024px)': {
                      height: 54,
                      width: 115,
                    },
                  }}
                  viewBox="0 0 115 54"
                >
                  <g>
                    <path
                      fill="#fff"
                      d="M3.7,46.8l0-43.7c0-0.5,0.4-1,1-1c0.5,0,1,0.4,1,1l0,20.6c1-2.5,3.9-6.1,9.9-6.1c9.1,0,11.3,5.4,11.3,11.2l0,18c0,0.5-0.4,1-1,1c-0.5,0-0.9-0.4-0.9-1l0-18c0-5.1-1.8-9.6-9.5-9.6c-6.9,0-9.9,5.3-9.9,10.2l0,17.4c0,0.5-0.4,1-1,1C4.1,47.8,3.7,47.3,3.7,46.8z"
                    />
                    <path
                      fill="#fff"
                      d="M46.1,17.6c10.5,0,11.9,7.9,11.9,12.1c0,0.7,0,1.6-0.1,2.3c-0.1,0.7-0.6,1-1.4,1l-21.4,0c0,9.1,4.2,13.4,11.7,13.4c4.8,0,7.1-1.6,8.9-3.2c0.2-0.1,0.3-0.2,0.5-0.2c0.4,0,0.8,0.4,0.8,0.8c0,0.3-0.2,0.5-0.4,0.7c-1,1-4.1,3.5-9.9,3.5c-8.4,0-13.6-4.6-13.6-15.3C33.2,23,38,17.6,46.1,17.6z M46.1,19.2c-7.2,0-10.7,4.7-11,12.2l21,0c0,0,0.1-1.3,0.1-1.8C56.2,24.6,54.3,19.2,46.1,19.2z"
                    />
                    <path
                      fill="#fff"
                      d="M66.5,3.1l0,37.8c0,3.7,1.3,5.2,4,5.2c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8c-3.8,0-5.9-2.3-5.9-6.8l0-37.8c0-0.5,0.4-1,1-1C66.1,2.1,66.5,2.6,66.5,3.1z"
                    />
                    <path
                      fill="#fff"
                      d="M78.9,3.1l0,37.8c0,3.7,1.3,5.2,4,5.2c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8c-3.8,0-5.9-2.3-5.9-6.8l0-37.8c0-0.5,0.4-1,0.9-1C78.5,2.1,78.9,2.6,78.9,3.1z"
                    />
                    <path
                      fill="#fff"
                      d="M113.4,32.8c0,10.4-4.9,15.3-13,15.3c-8.1,0-13-4.9-13-15.3s4.9-15.2,13-15.2C108.5,17.6,113.4,22.4,113.4,32.8z M111.3,32.8c0-9.3-3.9-13.6-11-13.6c-7.1,0-11,4.2-11,13.6c0,9.4,3.9,13.6,11,13.6C107.5,46.4,111.3,42.2,111.3,32.8z"
                    />
                  </g>
                </svg>
              </div>
              <div>
                <p>
                  If you are a landlord looking to sell off your portfolio...
                </p>
                <p>
                  If you’re a homeowner looking for a quick sale on your home...
                </p>
                <p>
                  If you’re an investor looking for better returns on your
                  savings...
                </p>
                <p>
                  <strong>...we can help.</strong>
                </p>
              </div>
            </div>
          </section>
          <section id="three">
            <div
              css={{
                borderRadius: 15,
                boxShadow: `  
                    0 1.4px 1.1px rgba(0, 0, 0, 0.034),
                    0 3.3px 2.65px rgba(0, 0, 0, 0.048),
                    0 6.25px 5px rgba(0, 0, 0, 0.06),
                    0 11px 9px rgba(0, 0, 0, 0.072)
                  `,
                float: 'right',
                marginLeft: 45,
                marginBottom: 16,
                overflow: "hidden",
                transform: 'rotate(3deg)',
                width: 100,
                '@media (min-width: 768px)': {
                  width: 150,
                },
                '@media (min-width: 1024px)': {
                  width: 200,
                }
              }}
            >
              <img
                alt={'Sheila Smith, Property Investor'}
                css={{
                  display: "block",
                  height: 100,
                  '@media (min-width: 768px)': {
                    height: 150,
                  },
                  '@media (min-width: 1024px)': {
                    height: 200,
                  }
                }}
                src={Sheila}
              />
            </div>
            <h2>Who we are</h2>
            <p>
              Lumin Property is run by Sheila Smith. We buy, renovate and rent
              property in the UK for ourselves and our investors.
            </p>
            <p>Get in touch with us today to see how we can help you.</p>
            <div className="row">
              <div className="8u 12u$(small)">
                <form
                  name={'lumin-properties-contact'}
                  onSubmit={this.handleSubmit}
                  data-netlify-honeypot="bot-field"
                  data-netlify="true"
                >
                  <input
                    type="hidden"
                    name="form-name"
                    value="lumin-properties-contact"
                  />
                  <input type="hidden" name="bot-field" />
                  <div className="row uniform 50%">
                    <div className="6u 12u$(xsmall)">
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={this.handleChange}
                        placeholder="Name"
                      />
                      {errorName !== '' && (
                        <p css={cssErrorMessage}>{errorName}</p>
                      )}
                    </div>
                    <div className="6u 12u$(xsmall)">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        placeholder="Email"
                      />
                      {errorEmail !== '' && (
                        <p css={cssErrorMessage}>{errorEmail}</p>
                      )}
                    </div>
                    <div className="12u">
                      <textarea
                        name="message"
                        onChange={this.handleChange}
                        placeholder="Message"
                        rows="4"
                        value={message}
                      />
                      {errorMessage !== '' && (
                        <p css={cssErrorMessage}>{errorMessage}</p>
                      )}
                    </div>
                  </div>
                  <div css={buttonAndMessage}>
                    <ul className="actions">
                      <li>
                        <input
                          type="submit"
                          onClick={this.handleSubmit}
                          value="Send"
                        />
                      </li>
                    </ul>
                    {showSuccess && (
                      <p>Thanks! We'll be in touch with you soon!</p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
